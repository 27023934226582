<template>
  <div class="page-table only-y mainDiv mb-30">
    <div class="baslikBg animated fadeInUp">Proje Rapor</div>

    <div
      class="page-header card-base card-shadow--medium animated fadeInUp"
      v-loading="loading"
      :element-loading-text="
        loading ? $t('src.views.apps.rapor.projeRaporLoading') : ''
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-col
        :lg="24"
        :md="24"
        :sm="24"
        :xs="24"
        style="display: flex; justify-content: flex-end; align-items: flex-end"
        v-if="isDesktop"
      >
        <div class="display-end">
          <ul class="islemler">
            <el-button
              type="text"
              v-on:click="getProjeRapor()"
              style="font-family: 'Poppins', sans-serif; font-size: 14px"
            >
              <span style="color: #4a596a">
                Raporu indirmek için tıklayınız.
              </span>
            </el-button>
          </ul>
          &nbsp;
          <el-tooltip :content="'Proje Rapor İndir'" :open-delay="500">
            <el-button
              type="text"
              style="color: #ee1010"
              v-on:click="getProjeRapor()"
              class="pdf-button"
            >
              <i class="mdi mdi-download mdi-24px"></i>
            </el-button>
          </el-tooltip>
        </div>
      </el-col>

      <el-col
        :lg="24"
        :md="24"
        :sm="24"
        :xs="24"
        style="
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
        "
        v-else
      >
        <div class="display-end-sol" style="display: flex; align-items: center">
          <el-button
            type="text"
            style="color: #ee1010"
            v-on:click="getProjeRapor()"
            class="pdf-button"
          >
            <i class="mdi mdi-download mdi-24px"></i>
          </el-button>
          <ul class="islemler">
            <el-button
              type="text"
              v-on:click="getProjeRapor()"
              style="font-family: 'Poppins', sans-serif; font-size: 14px"
            >
              <span style="color: #4a596a">
                Raporu indirmek için tıklayınız.
              </span>
            </el-button>
          </ul>
        </div>
      </el-col>

      <el-table
        :data="projeList"
        style="width: 100%"
        border
        id="projerapor"
        height="600"
      >
        <el-table-column
          fixed="left"
          label="Proje"
          align="center"
          prop="projeBaslik"
          width="180"
        >
          <template slot="header">
            <div style="color: #00385a">Proje</div>
          </template>
          <template slot-scope="props">
            <div class="new-line-text">
              {{ props.row.projeBaslik }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-for="(item, index) in tarihler2"
          :key="index"
          align="center"
          width="200"
        >
          <template slot="header">
            <div class="new-line-text">
              <div
                v-if="shouldDisplayYear(index)"
                style="
                  height: 25px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 14px !important;
                "
              >
                {{ item.year }}
              </div>
              <div
                style="
                  height: 25px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 14px !important;
                "
              >
                {{ item.month }}
              </div>
            </div>
          </template>

          <template slot-scope="prop">
            <div style="padding: 10px">
              <div
                class="sozlesmeRow new-line-text"
                v-for="(sozlesme, i) in checkMonths(prop.row, item)"
                :key="i"
              >
                <span>{{ sozlesme.sozlesmeBaslik }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
import notification from "../../../notification";
import functions from "../../../functions";
import wsProvider from "../../../WSProvider/provider/WSProvider";
import projeService from "../../../WSProvider/ProjeService";
import sozlesmeService from "../../../WSProvider/SozlesmeService";

var moment = require("moment");

export default {
  name: "ProjeRapor",
  data() {
    return {
      displayedYears: new Set(),
      isDesktop: false,
      loading: false,
      projeList: [],
      sozlesmeList: [],
      selectIcon: "1",
      sizes: 10,
      page: 0,
      postPage: 0,
      total: 4,
      tarihler: [],
      tarihler2: [],
    };
  },

  created() {
    this.checkDevice();
  },

  mounted() {
    this.getProjeListesi();
    //this.getSozlesmeListesi();
    // daysInmonth fonksiyonu bulundugumuz yıla gore bütün aylardaki günleri hesaplıyor. Kaç gün olduklarını

    window.addEventListener("resize", this.checkDevice);
  },

  /*
  mounted() {
  Promise.all([this.getProjeListesi(), this.getSozlesmeListesi()])
    .then(() => {
      this.tarihler = functions.daysInMonths();

      this.projeList.forEach((proje) => {
        proje.sozlesmeler.forEach((sozlesme) => {
          sozlesme.sozlesmeAylari = getMonthsBetween(sozlesme.baslamaTarihi, sozlesme.isTeslimTarihi);
        });
      });
    })
    .catch((error) => {
      // Hata yönetimi
    });

  window.addEventListener('resize', this.checkDevice);
},*/

  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },

  methods: {
    shouldDisplayYear(index) {
      const year = this.tarihler2[index].year;

      if (!this.displayedYears.has(year)) {
        this.displayedYears.add(year);
        return true;
      }
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },
    /*
    checkMonths(proje, month) {
      let result = [];

      this.projeList.forEach((proje) => {
        proje.sozlesmeler.forEach((sozlesme) => {
          sozlesme.sozlesmeAylari = getMonthsBetween(sozlesme.baslamaTarihi, sozlesme.isTeslimTarihi);
        });
      });

      proje.sozlesmeler.forEach((sozlesme) => {
        sozlesme.sozlesmeAylari.forEach((ay) => {
          ay.monthIndex == month.monthIndex ? result.push(sozlesme) : "";
        });
      });

      return result;
    },*/

    checkMonths(proje, month) {
      const result = [];
      const sozlesmeler = new Set();

      if (!proje.sozlesmeler) return result;
      for (const sozlesme of proje.sozlesmeler) {
        sozlesme.sozlesmeAylari = getMonthsBetween(
          sozlesme.baslamaTarihi,
          sozlesme.isTeslimTarihi
        );

        for (const ay of sozlesme.sozlesmeAylari) {
          if (
            ay.monthIndex === month.monthIndex &&
            ay.year === month.year &&
            !sozlesmeler.has(sozlesme)
          ) {
            result.push(sozlesme);
            sozlesmeler.add(sozlesme);
            break; // Eşleşme bulunduğunda döngüyü sonlandırıyoruz
          }
        }
      }

      return result;
    },

    getProjeRapor() {
      const token = localStorage.getItem("userDataBGSurec");
      const formData = new FormData();
      formData.append("token", token);
      formData.append("serviceName", "proje");
      formData.append("methodName", "projeRapor");

      const xhr = new XMLHttpRequest();
      xhr.open("POST", wsProvider.apiPath + "webservis.php");
      xhr.responseType = "blob";

      xhr.onload = function () {
        const url = window.URL.createObjectURL(xhr.response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "projerapor.pdf");
        document.body.appendChild(link);
        link.click();
      };

      xhr.onerror = function () {
        console.log("Error occured during the request");
      };

      xhr.send(formData);
    },

    getProjeSayisi() {
      try {
        projeService
          .projeSayisi(this.selectIcon)
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("userDataBGSurec", response.token);
            }
            this.total = parseInt(response.data);
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.total = 0;
              }
            } else {
              //notification.Status(503, this)
            }
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer number.")
      }
    },

    getProjeListesi() {
      var bugunTarih = new Date();
      try {
        this.loading = true;
        projeService
          .projeListesi(this.selectIcon, this.postPage, 50, 10)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.total = parseInt(response.data.projeSayisi);
              try {
                this.projeList = response.data;
                console.log("projeList => ", this.projeList);
                this.tarihler = functions.daysInMonths();

                this.total = parseInt(response.count);
                this.projeList.forEach((proje) => {
                  proje.bitenyuzdedeger = 0;

                  if (proje.sozlesmeler && proje.sozlesmeler.length > 0) {
                    proje.sozlesmeler.forEach((sozlesme) => {
                      sozlesme.toplamProjeSuresi = 0;
                      sozlesme.baslamaGecenSure = 0;
                      sozlesme.baslamaSureYuzde = 0;
                      sozlesme.kalanSure = 0;

                      sozlesme.toplamProjeSuresi = getDays(
                        sozlesme.baslamaTarihi,
                        sozlesme.tamamlanmaTarihi
                      );
                      projeBasladiMi(
                        moment().format("YYYY-MM-DD"),
                        sozlesme.baslamaTarihi
                      )
                        ? (sozlesme.baslamaGecenSure = getDays(
                            sozlesme.baslamaTarihi,
                            moment().format("YYYY-MM-DD")
                          ))
                        : (sozlesme.baslamaGecenSure = 0);
                      sozlesme.kalanSure =
                        sozlesme.toplamProjeSuresi - sozlesme.baslamaGecenSure;
                      if (sozlesme.kalanSure < 0) {
                        sozlesme.kalanSure = 0;
                      }

                      sozlesme.baslamaSureYuzde = parseFloat(
                        (parseInt(sozlesme.baslamaGecenSure) * 100) /
                          parseInt(sozlesme.toplamProjeSuresi)
                      ).toFixed(1);
                      if (sozlesme.baslamaSureYuzde > 100) {
                        sozlesme.baslamaSureYuzde = 100;
                      }

                      sozlesme.toplamSozlesme = 0;
                      sozlesme.bitenSozlesme = 0;

                      sozlesme.toplamSozlesme += parseFloat(
                        sozlesme.yuzdeDeger
                      );
                      sozlesme.bitenSozlesme += sozlesmeBittiMi(
                        moment().format("YYYY-MM-DD"),
                        sozlesme.tamamlanmaTarihi
                      )
                        ? parseFloat(sozlesme.yuzdeDeger)
                        : 0;

                      sozlesme.bitmeYuzdesi = parseFloat(
                        (sozlesme.bitenSozlesme * 100) / sozlesme.toplamSozlesme
                      ).toFixed(1);
                      sozlesme.toplamYuzde = parseFloat(
                        (sozlesme.yuzdeDeger * 100) / sozlesme.toplamSozlesme
                      ).toFixed(1);
                      sozlesme.toplamCalisilanGun = 0;

                      sozlesme.sozlesmeAylari = getMonthsBetween(
                        sozlesme.baslamaTarihi,
                        sozlesme.tamamlanmaTarihi
                      );

                      if (
                        sozlesme.sozlesmeAylari &&
                        sozlesme.sozlesmeAylari.length > 0
                      ) {
                        sozlesme.sozlesmeAylari.forEach((ay) => {
                          sozlesme.toplamCalisilanGun += parseInt(ay.days);
                        });

                        sozlesme.sozlesmeAylari.forEach((ay, index) => {
                          var daysInMonth = this.tarihler.find(
                            (e) => e.monthIndex == ay.monthIndex
                          );
                          ay.dayPercentage =
                            sozlesme.sozlesmeAylari.length == 1
                              ? parseFloat(
                                  (parseInt(ay.days) * 100) / daysInMonth.days
                                ).toFixed(1)
                              : index > 0 &&
                                index < sozlesme.sozlesmeAylari.length - 1
                              ? 100
                              : (
                                  parseFloat(
                                    (parseInt(ay.days) * 100) / daysInMonth.days
                                  ) + 3
                                ).toFixed(1) + 5;
                        });

                        sozlesme.sozlesmeAylari.forEach((ay) => {
                          // console.log("ay => ", ay);
                          // console.log("tariherler2 => ", this.tarihler2);
                          // Ay nesnesinin tüm özelliklerini tarihler2 dizisine ekliyoruz
                          if (
                            !this.tarihler2.some(
                              (item) =>
                                item.month === ay.month && item.year === ay.year
                            )
                          ) {
                            this.tarihler2.push(ay);
                          }
                        });
                      }

                      sozlesme.bitti = checkDateRange(
                        sozlesme.baslamaTarihi,
                        sozlesme.tamamlanmaTarihi
                      );
                    });
                  }
                });
                this.tarihler2.sort((a, b) => {
                  if (a.year !== b.year) {
                    return a.year - b.year; // İlk olarak yıllara göre sıralama
                  } else {
                    return a.monthIndex - b.monthIndex; // Yıllar aynıysa aylara göre sıralama
                  }
                });
              } catch (e) {
                console.error("e  =>  ", e);
              }
              // console.log(" response =>    ", response.data);

              //console.log("PROJE RAPOR");
              //console.log(this.projeList);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.projeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
        // console.log(e);
      }
    },

    getSozlesmeListesi() {
      try {
        this.loading = true;
        sozlesmeService
          .sozlesmeListesi(this.selectIcon, this.postPage, 55)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.total = parseInt(response.data.sozlesmeSayisi);
              response.data.forEach((el) => {
                /*el.dogumSaati = el.dogumTarihi != '0000-00-00 00:00:00' ? moment(el.dogumTarihi).format('hh:mm:ss') : ''
                                el.dogumGunu = el.dogumTarihi != '0000-00-00 00:00:00' ? moment(el.dogumTarihi).format('YYYY-MM-DD') : ''
                                el.kayitSaati = el.kayitTarih != '0000-00-00 00:00:00' ? moment(el.kayitTarih).format('hh:mm:ss') : ''
                                el.kayitGunu = el.kayitTarih != '0000-00-00 00:00:00' ? moment(el.kayitTarih).format('YYYY-MM-DD') : ''*/
              });
              this.sozlesmeList = response.data;
              this.total = parseInt(response.count);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },

    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },

    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;

      // this.getProjeSayisi();
      this.getProjeListesi();
    },
  },
};

// verilen baslangıc ve bitis tarihleri arasında hangi aylar var başlangıc ve bitişte dahil. Ve o aylar başlangıc ve bitiş aralıgında kacar gün dahil edilmiş
function getDays(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür ve gün farkını hesapla
  const tarih1Moment = moment(tarih1);
  const tarih2Moment = moment(tarih2);
  const gunFarki = tarih2Moment.diff(tarih1Moment, "days");

  // Sonucu döndür
  return gunFarki;
}
function sozlesmeBittiMi(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür
  const momentTarih1 = moment(tarih1);
  const momentTarih2 = moment(tarih2);

  // Tarih1 tarih2'den sonra mı?
  return momentTarih1.isAfter(momentTarih2);
}

function projeBasladiMi(bugun, baslangicTarihi) {
  const momentBugun = moment(bugun);
  const momentBaslangic = moment(baslangicTarihi);

  return momentBugun.isAfter(momentBaslangic);
}
function checkDateRange(start, end) {
  const today = moment();
  if (today.isBefore(start, "day")) {
    return 0;
  } else if (today.isBetween(start, end, "day")) {
    return 1;
  } else {
    return 2;
  }
}

function getMonthsBetween(start, end) {
  moment.locale("tr");
  const results = [];

  for (
    let m = moment(start);
    m.isSameOrBefore(end, "month");
    m.add(1, "months")
  ) {
    const monthStart = moment(m).startOf("month");
    const monthEnd = moment(m).endOf("month");

    let daysInMonth = monthEnd.diff(monthStart, "days") + 1;

    if (m.isSame(moment(start).startOf("month"), "month")) {
      daysInMonth -= moment(start).date();
    }
    if (m.isSame(moment(end).endOf("month"), "month")) {
      daysInMonth -= monthEnd.date() - moment(end).date();
    }

    results.push({
      year: monthStart.format("YYYY"), // Yıl bilgisini ekledik
      month: monthStart.format("MMMM"),
      monthIndex: parseInt(monthStart.month()) + 1,
      days: daysInMonth,
    });
  }

  return results;
}
</script>

<style lang="scss">
#projerapor {
  .el-table__cell {
    .cell {
      padding: 0 !important;
    }
  }

  .el-progress-bar__innerText {
    color: white !important;
  }

  thead th {
    background: #c7e3f6 !important;
    color: #00385a;
  }
}

.sozlesmeRow:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}
</style>
